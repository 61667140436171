<template>
  <v-card outlined :loading="loading">
    <v-card-title>
      <div class="heading">Accountability Setup</div>
    </v-card-title>
    <v-card-text>

      <div class="mb-2">Setting up {{appName}} Accountability is easy and only takes a few minutes.</div>

      <div class="text-h5">Recommended Steps</div>

      <ol style="padding-left: 40px;">
        <li style="margin: 5px;">
          <completed-icon :completed="setupProgress.onboardingSurvey" style="margin-left: -45px;" />
          <span class="font-weight-bold" style="margin-left: 18px;">Take the onboarding survey.</span>
          <span class="pl-1 font-italic">(~1 minute)</span>
          <span v-if="!setupProgress.onboardingSurvey" class="font-italic error--text pl-2">(incomplete)</span>
          <br />
          <v-btn color="primary" small @click="showOnboardingSurvey = true">Take survey</v-btn>
        </li>
        <li style="margin: 5px;">
          <completed-icon :completed="setupProgress.addDevices" style="margin-left: -45px;" />
          <span class="font-weight-bold" style="margin-left: 18px;">Add devices to your account</span>
          <span class="pl-1 font-italic">(~3 minutes / device)</span>
          <span v-if="!setupProgress.addDevices" class="font-italic error--text pl-2">(incomplete)</span>
          <br />

          <v-btn color="primary" small @click="showAddDeviceDialog = true">Download app</v-btn>
          <div class="caption font-italic mt-1">
            Repeat for each device that needs to be monitored.
          </div>
          <add-device-dialog v-model="showAddDeviceDialog" />

<!--          <v-btn text @click="showGif('Add Devices', '/static/img/setup/addDevice2.gif',`<ol><li>Open the Menu</li><li>Click Devices</li><li>Click Add a Device</li><li>Select the device type, and follow the steps shown.</li></ol>`)" v-if="!seeHow.devices">-->
<!--            View Instructions-->
<!--          </v-btn>-->
<!--          <ol>-->
<!--            <li>-->
<!--              <div>Click the Menu</div>-->
<!--              <img class="image-border" src="/static/img/setup/menu.png" />-->
<!--            </li>-->
<!--            <li>-->
<!--              <div>Click Devices</div>-->
<!--              <img class="image-border" src="/static/img/setup/menu.devices.png" />-->
<!--            </li>-->
<!--            <li>-->
<!--              <div>Click Add a Device</div>-->
<!--              <img class="image-border" src="/static/img/setup/add.a.device.png" />-->
<!--            </li>-->
<!--            <li>-->
<!--              <div>Choose a device type, and follow the remaining instructions.</div>-->
<!--            </li>-->
<!--          </ol>-->
        </li>
        <li style="margin: 5px;">
          <completed-icon :completed="setupProgress.addAccountabilityPartner" style="margin-left: -45px;" />
          <span class="font-weight-bold" style="margin-left: 18px;">Setup daily/weekly reports and alerts</span>
          <span class="pl-1 font-italic">(1 minute)</span>
          <span v-if="!setupProgress.addAccountabilityPartner" class="font-italic error--text pl-2">(incomplete)</span>
          <br />
          <v-btn color="primary" small @click="showGif('Add Report Recipient', '/static/img/setup/addAccountabilityPartner.gif', `<ol><li>Open the Menu</li><li>Click Devices</li><li>Click Reporting & Alerts</li><li>Click Add Report Recipient.</li></ol>`)" v-if="!seeHow.devices">
            View Instructions
          </v-btn>
        </li>
        <li style="margin: 5px;">
          <completed-icon :completed="setupProgress.e2ee" style="margin-left: -45px;" />
          <span class="font-weight-bold" style="margin-left: 18px;">Enable end-to-end encryption</span>
          <span class="pl-1 font-italic">(2 minutes)</span>
          <span v-if="!setupProgress.e2ee" class="font-italic error--text pl-2">(incomplete)</span>
          <div>Enabling end-to-end encryption will greatly increase the security of your accountability data.</div>
          <v-btn color="primary" small @click="showGif('Enable E2ee', '/static/img/setup/e2ee.gif', `<ol><li>Open the Menu</li><li>Click Account</li><li>Scroll to the bottom, then click Enable E2E Encryption</li><li>Read the information presented, and complete the form.</li></ol>`)" v-if="!seeHow.devices">
            View Instructions
          </v-btn>
        </li>
      </ol>

      <div class="font-italic">If you have any problems, please email <a href="mailto:support@truple.io">support@truple.io</a>.</div>

<!--      <template v-if="setupProgress.minimumSet">-->
        <br />

        <div>
          <span class="text-h5 pr-2">Optional Steps</span>
          <v-btn text class="ma-0 pa-0 text--theme--primary font-italic" style="cursor: pointer;" @click="optionalShown = !optionalShown">{{(optionalShown ? 'hide' : 'show')}}</v-btn>
        </div>
      <v-expand-transition>
        <ul style="padding-left: 40px;" v-if="optionalShown">
          <li style="margin: 5px;">
            <completed-icon :completed="false" :optional="true" :indeterminate="true" style="margin-left: -45px;" />
            <span class="font-weight-bold" style="margin-left: 18px;">Adjust Device Settings</span>
            <span class="font-italic pl-1">(optional, 2 minutes)</span>
            <div>When devices are added to your account, they use default settings that work well under most circumstances.  However you can further customize those settings.</div>
            <v-btn text @click="showGif('Device Settings', '/static/img/setup/deviceSettings.gif')" v-if="!seeHow.devices">
              View Instructions
            </v-btn>
          </li>
          <li style="margin: 5px;">
            <completed-icon :completed="false" :optional="true" style="margin-left: -45px;" />
            <span class="font-weight-bold" style="margin-left: 18px;">Lock account</span>
            <span class="font-italic pl-1">(optional, 1 minute)</span>
            <div>If you wish, you can lock your account.  Locking will prevent changes from being made on your account.  In order to unlock, an unlock passcode will be sent to a report recipient that you must then enter in.</div>
            <v-btn text @click="showGif('Lock Account', '/static/img/setup/lock.gif')" v-if="!seeHow.devices">
              View Instructions
            </v-btn>
          </li>
        </ul>
      </v-expand-transition>
<!--      </template>-->

    </v-card-text>

    <gif-dialog v-if="gif.show" :title="gif.title" :src="gif.src" :show="gif.show" :text-steps="gif.textSteps" @close="closeGifDialog" />

    <survey-onboarding v-model="showOnboardingSurvey" />
  </v-card>
</template>

<script>
  import SurveyOnboarding from "@/components/surveys/SurveyOnboarding";
  import CompletedIcon from "../CompletedIcon";
  import GifDialog from "./GifDialog";
  import StoreConstants from "../../StoreConstants";
  import AddDeviceDialog from "@/components/devices/AddDeviceDialog";

  let unsubscribe = null

  export default {
    name: "SetupScreenshotAccountability",
    components: {AddDeviceDialog, GifDialog, CompletedIcon, SurveyOnboarding},
    data() {
      return {
        appName: process.env.VUE_APP_NAME,
        showAddDeviceDialog: false,
        showOnboardingSurvey: false,
        loading: true,
        optionalShown: false,
        seeHow: {
          devices: false
        },
        setupProgress: {
          addDevices: false,
          addAccountabilityPartner: false,
          e2ee: false
        },
        gif: {
          title: '',
          src: '',
          show: false,
          textSteps: null
        }
      }
    },
    beforeMount() {
      unsubscribe = this.$store.subscribe((mutation, state) => {
        if(mutation.type === StoreConstants.commits.setAccount) {
          if(this.$store.state.account) {
            if(this.$store.state.account.account.setupProgress) {
              this.setupProgress = this.$store.state.account.account.setupProgress
              this.optionalShown = this.setupProgress.minimumSet
            }
          }
        }
      })

      this.loadAccountProgress()

      console.log('beforeMount')

      if(this.$store.state.account && this.$store.state.account.account.setupProgress && (Date.now() - this.$store.state.account.account.setupProgress.lastSet > 5000)) {
        this.$store.dispatch(StoreConstants.actions.loadAccount)
      }
    },
    beforeDestroy() {
      if(unsubscribe) {
        unsubscribe()
      }
    },
    methods: {
      async loadAccountProgress() {
        this.loading = true
        await this.$store.state.accountLoadedPromise
        if(this.$store.state.account) {
          if(this.$store.state.account.account.setupProgress) {
            this.setupProgress = this.$store.state.account.account.setupProgress
            this.optionalShown = this.setupProgress.minimumSet
          }
        }
        this.loading = false
      },
      closeGifDialog() {
        this.gif.show = false
      },
      showGif(title, src, textSteps) {

        console.log('show')

        this.gif.title = title
        this.gif.src = src
        this.gif.show = true
        this.gif.textSteps = textSteps

        // this.seeHow[val] = true
        // setTimeout(() => {
        //   this.seeHow[val] = false
        // }, delay)
      }
    }
  }
</script>

<style scoped>
  .image-border {
    border: 2px solid #FFC107;
  }
</style>