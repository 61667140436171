var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "600px",
        scrollable: "",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.smAndDown
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "primary" },
            [
              _c("span", { staticClass: "white--text" }, [
                _vm._v("Onboarding Survey")
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: { click: _vm.closeOnboardingSurvey }
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [_vm._v("close")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _vm.currentQuestion === 0
                ? _c(
                    "survey-question",
                    {
                      attrs: {
                        "back-button-text": null,
                        "next-disabled":
                          !_vm.answers.devices ||
                          Object.keys(_vm.answers.devices).length === 0
                      },
                      on: {
                        "on-next": function($event) {
                          _vm.currentQuestion = 1
                        }
                      }
                    },
                    [
                      _c("multiple-choice", {
                        attrs: {
                          question:
                            "Which device types do you need to monitor?",
                          "potential-answers": [
                            "Android",
                            "Windows",
                            "Mac",
                            "iPhone / iPad",
                            "Kindle Fire",
                            "Chromebook",
                            "Linux"
                          ],
                          "multiple-answers": true
                        },
                        model: {
                          value: _vm.answers.devices,
                          callback: function($$v) {
                            _vm.$set(_vm.answers, "devices", $$v)
                          },
                          expression: "answers.devices"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentQuestion === 1
                ? _c(
                    "survey-question",
                    {
                      attrs: { "next-disabled": !_vm.answers.source },
                      on: {
                        "on-back": function($event) {
                          _vm.currentQuestion = 0
                        },
                        "on-next": function($event) {
                          _vm.currentQuestion = 2
                        }
                      }
                    },
                    [
                      _c("multiple-choice", {
                        attrs: {
                          question:
                            "How did you learn about " + _vm.appName + "?",
                          "potential-answers": _vm.learnAboutAnswers,
                          other: true,
                          randomize: true
                        },
                        model: {
                          value: _vm.answers.source,
                          callback: function($$v) {
                            _vm.$set(_vm.answers, "source", $$v)
                          },
                          expression: "answers.source"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentQuestion === 2
                ? _c(
                    "survey-question",
                    {
                      attrs: {
                        "next-button-text": "Submit",
                        "next-disabled": !_vm.answers.organization,
                        "next-loading": _vm.submitting
                      },
                      on: {
                        "on-back": function($event) {
                          _vm.currentQuestion = 1
                        },
                        "on-next": _vm.submit
                      }
                    },
                    [
                      _c("yes-no", {
                        attrs: {
                          question:
                            "Are you a part of an organization, church, or group that would be interested in partnering with " +
                            _vm.appName +
                            " to provide group discounts?"
                        },
                        model: {
                          value: _vm.answers.organization,
                          callback: function($$v) {
                            _vm.$set(_vm.answers, "organization", $$v)
                          },
                          expression: "answers.organization"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }